import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login";
import Account from "./Account";
import Stocks from "./Stocks";
import Positions from "./Positions";
import MobileLayout from "./components/MobileLayout";

const YiStock: React.FC = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route element={<MobileLayout />}>
        <Route path="stocks" element={<Stocks />} />
        <Route path="positions" element={<Positions />} />
        <Route path="account" element={<Account />} />
        {/* 默认重定向到股票列表 */}
        <Route path="" element={<Navigate to="stocks" replace />} />
      </Route>
    </Routes>
  );
};

export default YiStock;
