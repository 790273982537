import React from "react";
import { Layout } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  StockOutlined,
  AccountBookOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Content } = Layout;

const MobileLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      icon: <StockOutlined />,
      text: "股票",
      path: "/YiStock/stocks",
    },
    {
      icon: <AccountBookOutlined />,
      text: "持仓",
      path: "/YiStock/positions",
    },
    {
      icon: <UserOutlined />,
      text: "我的",
      path: "/YiStock/account",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", background: "#f5f5f5" }}>
      <Content style={{ padding: "10px", paddingBottom: "60px" }}>
        <Outlet />
      </Content>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          height: "50px",
          background: "#fff",
          display: "flex",
          borderTop: "1px solid #f0f0f0",
        }}
      >
        {navItems.map((item) => (
          <div
            key={item.path}
            onClick={() => navigate(item.path)}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "12px",
              color: location.pathname === item.path ? "#1890ff" : "#666",
              cursor: "pointer",
            }}
          >
            {React.cloneElement(item.icon, {
              style: { fontSize: "20px", marginBottom: "2px" },
            })}
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default MobileLayout;
