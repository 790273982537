import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import request from "./utils/request";

interface LoginForm {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as any)?.from || "/YiStock/Account";

  const onFinish = async (values: LoginForm) => {
    try {
      const response = await request.post("/login", values);
      if (response.status === 200) {
        let data = response.data;
        if (data.ret === 0) {
          message.success("登录成功");
          navigate(from, { replace: true });
        } else {
          message.error(data.msg);
        }
      }
    } catch (error) {
      // 错误已经被 request 拦截器处理
    }
  };

  return (
    <div style={{ 
      padding: "20px",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: "#f5f5f5",
    }}>
      <Form 
        onFinish={onFinish}
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <h2 style={{ 
          textAlign: "center", 
          marginBottom: "24px",
          color: "#333",
        }}>
          账户登录
        </h2>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "请输入用户名" }]}
        >
          <Input placeholder="用户名" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "请输入密码" }]}
        >
          <Input.Password placeholder="密码" size="large" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
