import React from "react";
import ReactDOM from "react-dom/client";
import Home from "@view/Home";
import { LuckyBox, Reward } from "@view/LuckyBox";
import { TCaseIndex, TCaseDetail, TCaseVerify } from "@view/TCase";
import { VexGoColorPad } from "@view/VexGo";
import YiStock from "@view/YiStock";
import { ShengXiao } from "@view/LuckyBox";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/luckybox",
    element: <LuckyBox />,
  },
  {
    path: "/luckybox/reward",
    element: <Reward />,
  },
  {
    path: "/luckybox/shengxiao",
    element: <ShengXiao />,
  },
  {
    path: "/tcase",
    element: <TCaseIndex />,
  },
  {
    path: "/tcase/detail",
    element: <TCaseDetail />,
  },
  {
    path: "/tcase/verify",
    element: <TCaseVerify />,
  },
  {
    path: "/vexgo",
    element: <VexGoColorPad />,
  },
  {
    path: "/vexgo/colorpad",
    element: <VexGoColorPad />,
  },
  {
    path: "/YiStock/*",
    element: <YiStock />,
  },
]);

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(<RouterProvider router={router} />);
