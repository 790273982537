import React from "react";
import { Card } from "antd";

const Stocks: React.FC = () => {
  return (
    <Card title="股票列表" bordered={false}>
      {/* 股票列表内容 */}
      开发中...
    </Card>
  );
};

export default Stocks;
