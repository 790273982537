import React from "react";
import { Card } from "antd";

const Positions: React.FC = () => {
  return (
    <Card title="持仓明细" bordered={false}>
      {/* 持仓内容 */}
      开发中...
    </Card>
  );
};

export default Positions;
