import React, { useState } from "react";
import styled from "styled-components";

// 定义生肖组 - 使用原C代码的顺序
const ZODIAC_GROUPS = [
  ["蛇", "羊", "兔", "狗", "鼠", "牛"], // 第1组：8
  ["龙", "兔", "牛", "狗", "猴", "马"], // 第2组：4
  ["龙", "马", "牛", "羊", "猪", "鸡"], // 第3组：2
  ["龙", "虎", "鸡", "羊", "狗", "鼠"], // 第4组：1
];

// 生肖对应的结果映射 - 使用原C代码的映射关系
const RESULT_MAP: { [key: number]: string } = {
  9: "鼠",
  14: "牛",
  1: "虎",
  12: "兔",
  7: "龙",
  8: "蛇",
  6: "马",
  11: "羊",
  4: "猴",
  3: "鸡",
  13: "狗",
  2: "猪",
};

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 600px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;

  input[type="radio"] {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f5f5f5;
  min-height: 80px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ZodiacList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

const ZodiacItem = styled.div`
  width: 46px;
  height: 41px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &[data-zodiac="鼠"] {
    background-image: url("/static/images/shengxiao/shengxiao_鼠.png");
  }
  &[data-zodiac="牛"] {
    background-image: url("/static/images/shengxiao/shengxiao_牛.png");
  }
  &[data-zodiac="虎"] {
    background-image: url("/static/images/shengxiao/shengxiao_虎.png");
  }
  &[data-zodiac="兔"] {
    background-image: url("/static/images/shengxiao/shengxiao_兔.png");
  }
  &[data-zodiac="龙"] {
    background-image: url("/static/images/shengxiao/shengxiao_龙.png");
  }
  &[data-zodiac="蛇"] {
    background-image: url("/static/images/shengxiao/shengxiao_蛇.png");
  }
  &[data-zodiac="马"] {
    background-image: url("/static/images/shengxiao/shengxiao_马.png");
  }
  &[data-zodiac="羊"] {
    background-image: url("/static/images/shengxiao/shengxiao_羊.png");
  }
  &[data-zodiac="猴"] {
    background-image: url("/static/images/shengxiao/shengxiao_猴.png");
  }
  &[data-zodiac="鸡"] {
    background-image: url("/static/images/shengxiao/shengxiao_鸡.png");
  }
  &[data-zodiac="狗"] {
    background-image: url("/static/images/shengxiao/shengxiao_狗.png");
  }
  &[data-zodiac="猪"] {
    background-image: url("/static/images/shengxiao/shengxiao_猪.png");
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #40a9ff;
  }
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export default function ShengXiao() {
  const [selections, setSelections] = useState<string[]>([
    "无",
    "无",
    "无",
    "无",
  ]);
  const [result, setResult] = useState<string>("");

  const handleSelectionChange = (index: number, value: string) => {
    const newSelections = [...selections];
    newSelections[index] = value;
    setSelections(newSelections);
  };

  const calculateResult = () => {
    let sum = 0;
    selections.forEach((selection, index) => {
      if (selection === "有") {
        let c = Math.pow(2, 3 - index);
        console.log(c);
        sum += c;
      }
    });
    console.log(sum);
    setResult(RESULT_MAP[sum] || "未找到匹配的生肖");
  };

  return (
    <Container>
      <h2>猜生肖小游戏</h2>
      <p>请选择每组生肖中是否包含你想到的生肖</p>

      <GroupContainer>
        {ZODIAC_GROUPS.map((group, index) => (
          <Group key={index}>
            <ZodiacList>
              {group.map((zodiac, zIndex) => (
                <ZodiacItem key={zIndex} data-zodiac={zodiac} />
              ))}
            </ZodiacList>
            <RadioGroup>
              <RadioLabel>
                <input
                  type="radio"
                  name={`group-${index}`}
                  value="无"
                  checked={selections[index] === "无"}
                  onChange={(e) => handleSelectionChange(index, e.target.value)}
                />
                无
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`group-${index}`}
                  value="有"
                  checked={selections[index] === "有"}
                  onChange={(e) => handleSelectionChange(index, e.target.value)}
                />
                有
              </RadioLabel>
            </RadioGroup>
          </Group>
        ))}
      </GroupContainer>

      <Button onClick={calculateResult}>开始猜</Button>

      {result && (
        <Result>
          <h3>你想的生肖是：</h3>
          <ZodiacItem data-zodiac={result} />
        </Result>
      )}
    </Container>
  );
}
