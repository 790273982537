import React, { useState } from "react";
import { Avatar } from "antd";
import styled from "styled-components";
import { StarFilled } from "@ant-design/icons";

interface ScoreRowProps {
  name: string;
  avatarUrl: string;
}

const Container = styled.div`
  padding: 20px;
`;

const Row = styled.div`
  margin-bottom: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const UserName = styled.span`
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const ScoreIcons = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 5px 0;
`;

const StyledAvatar = styled(Avatar)`
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
  font-size: 24px !important;
`;

const ScoreIcon = styled.div<{ active: boolean }>`
  font-size: 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${(props) => (props.active ? "#FFD700" : "#e0e0e0")};
  padding: 8px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  user-select: none;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ScoreRow: React.FC<ScoreRowProps> = ({ name, avatarUrl }) => {
  const [scores, setScores] = useState<boolean[]>(new Array(10).fill(false));

  const toggleScore = (index: number) => {
    const newScores = [...scores];
    newScores[index] = !newScores[index];
    setScores(newScores);
  };

  return (
    <Row>
      <UserInfo>
        <StyledAvatar size={60} src={avatarUrl} />
        <UserName>{name}</UserName>
      </UserInfo>
      <ScoreIcons>
        {scores.map((active, index) => (
          <ScoreIcon
            key={index}
            active={active}
            onClick={() => toggleScore(index)}
          >
            <StarFilled />
          </ScoreIcon>
        ))}
      </ScoreIcons>
    </Row>
  );
};

const RewardPage: React.FC = () => {
  const users = [
    {
      name: "Yiyi",
      avatarUrl: "https://api.dicebear.com/9.x/fun-emoji/svg?seed=George",
    },
    {
      name: "Yoyo",
      avatarUrl: "https://api.dicebear.com/9.x/fun-emoji/svg?seed=Alexander",
    },
    {
      name: "Songzi",
      avatarUrl: "https://api.dicebear.com/9.x/fun-emoji/svg?seed=Emery",
    },
  ];

  return (
    <Container>
      {users.map((user, index) => (
        <ScoreRow key={index} name={user.name} avatarUrl={user.avatarUrl} />
      ))}
    </Container>
  );
};

export default RewardPage;
