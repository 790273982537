import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

// 获取测试环境的完整 URL
const getBaseURL = () => {
  return "http://localhost:19678/api/yistock";
};

const request = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true,
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      message.error("登录已过期，请重新登录", 1.5, () => {
        // window.location.href = "/YiStock/Login";
      });
    } else {
      const errorMessage =
        error.response?.data?.message || "操作失败，请稍后重试";
      message.error(errorMessage);
    }
    return Promise.reject(error);
  }
);

export default request;
