import React, { useEffect, useState } from "react";
import { Card, Descriptions, Spin } from "antd";
import request from "./utils/request";

interface AccountInfo {
  username: string;
  balances: {
    CNY: number;
    HKD: number;
    USD: number;
  };
}

const Account: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (hasFetched) return;

    let mounted = true;

    const fetchAccountInfo = async () => {
      try {
        const response = await request.get("/account/info");
        if (mounted && response.data.ret === 0) {
          setAccountInfo(response.data.data);
        }
      } catch (error) {
        // 错误已经被 request 拦截器处理
      } finally {
        if (mounted) {
          setLoading(false);
          setHasFetched(true);
        }
      }
    };

    fetchAccountInfo();

    return () => {
      mounted = false;
    };
  }, [hasFetched]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Card
      title="账户信息"
      bordered={false}
      style={{
        margin: "0 auto",
        background: "#fff",
        borderRadius: "8px",
      }}
    >
      {accountInfo && (
        <Descriptions
          column={1}
          size="small"
          labelStyle={{
            padding: "12px 0",
            color: "#666",
          }}
          contentStyle={{
            padding: "12px 0",
            color: "#333",
            fontWeight: "500",
          }}
        >
          <Descriptions.Item label="用户名">
            {accountInfo.username}
          </Descriptions.Item>
          <Descriptions.Item label="人民币余额">
            <span style={{ color: "#f50" }}>
              ¥ {accountInfo.balances.CNY.toFixed(2)}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="港币余额">
            <span style={{ color: "#f50" }}>
              HK$ {accountInfo.balances.HKD.toFixed(2)}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label="美元余额">
            <span style={{ color: "#f50" }}>
              $ {accountInfo.balances.USD.toFixed(2)}
            </span>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};

export default Account;
