import React, { useRef, useState } from "react";
import { Button, Col, Flex, Input, Result, Row } from "antd";
import { LuckyWheel, LuckyGrid } from "@lucky-canvas/react";
import { Simulate } from "react-dom/test-utils";
import chroma from "chroma-js";
import input = Simulate.input;

type Prize = {
  background: string;
  fonts: { text: string }[];
};

const colors = chroma.brewer.Set3;

function LuckyBox() {
  const [blocks] = useState([{ padding: "10px", background: "#869cfa" }]);

  const [inputValue, setInputValue] = useState("游泳,博物馆,跑步,读书,玩手机");
  const [prizes, setPrizes] = useState<Prize[]>([]);
  const [rank, setRank] = useState(0);
  const [loading, setLoading] = useState(false);

  const [buttons] = useState([
    { radius: "40%", background: "#617df2" },
    { radius: "35%", background: "#afc8ff" },
    {
      radius: "30%",
      background: "#869cfa",
      pointer: true,
      fonts: [{ text: "开始", top: "-10px" }],
    },
  ]);

  const resetPrizes = (event) => {
    const isCheat = rank % 2 == 0;
    if (isCheat) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }

    const prizeItems = inputValue.split(",");
    const newPrizes = prizeItems.map((prize, index) => ({
      background: colors[index],
      fonts: [{ text: prize.trim() }],
      range: isCheat
        ? index <= 3
          ? 25
          : 0
        : index <= 3
        ? 0
        : 100 / (prizeItems.length - 4),
    }));

    setPrizes(newPrizes);
    setRank(rank + 1);
  };

  const myLucky = useRef();

  return (
    <div>
      <Row justify="center" align="middle" style={{ height: "500px" }}>
        <Col>
          <LuckyWheel
            ref={myLucky}
            width="300px"
            height="300px"
            blocks={blocks}
            prizes={prizes}
            buttons={buttons}
            onStart={() => {
              // 点击抽奖按钮会触发star回调
              myLucky.current!.play();
              setTimeout(() => {
                const index = (Math.random() * 6) >> 0;
                myLucky.current!.stop(index);
              }, 2500);
            }}
            onEnd={(prize) => {
              // 抽奖结束会触发end回调
              // alert('恭喜你抽到 ' + prize.fonts[0].text + ' 号奖品')
            }}
          ></LuckyWheel>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} style={{ textAlign: "center", margin: "30px" }}>
          <Input
            style={{ width: "80%" }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Button onClick={resetPrizes} type="primary" loading={loading}>
            设定奖励
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default LuckyBox;
